import React from "react"
import { Link } from "gatsby"
import Layout from "../../layouts/home"
import { normalizePath } from "../../utils/get-url-path"
import FeaturedImage from "../../components/FeaturedImage"
import parse from "html-react-parser"
import { cleanExcerpt } from "../../utils"

function BlogPost({ data, location }) {
  const { nextPage, previousPage, page } = data
  let {
    title,
    excerpt,
    content,
    featuredImage,
    acf_postImage: {postImage},
    acf_page_content: { lead },
    seo,
  } = page

  if (featuredImage && postImage) {
      featuredImage.node = postImage;
    }

  // console.log(location)
  location.pathname = "/our-work/"

  return (
    <Layout
      location={location}
      featuredImage={featuredImage?.node}
      cssClass="casestudy"
      title={title}
      description={excerpt}
      seo={seo}
    >
      <section>
        <div className="row">
          <div className="col-sm-7 col-lg-11 col-xl-10">
            <p className="lead">{lead}</p>
            <h1
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></h1>
          </div>
          <FeaturedImage image={featuredImage?.node} classes="mobile" />
        </div>
        <div className="row">
          <div className="post col-sm-12">
            <div className="cardwrapper">
              <div className="card">
                <div>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: cleanExcerpt(excerpt),
                    }}
                  ></h2>
                </div>
                {parse(content)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 pagination">
          <ul>
            {!!previousPage ? (
              <li className="im im-angle-left">
                <Link
                  className="previous"
                  title={previousPage.title}
                  to={normalizePath(previousPage.uri)}
                ></Link>
              </li>
            ) : (
              <li></li>
            )}
            {!!nextPage ? (
              <li className="im im-angle-right">
                <Link
                  className="next"
                  title={nextPage.title}
                  to={normalizePath(nextPage.uri)}
                ></Link>
              </li>
            ) : (
              <li></li>
            )}
          </ul>
        </div>
        <div className="col-md-12 mb-5 d-none">
          <Link to="/our-work/" className="blue">
            &lt; Back to our works
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost
