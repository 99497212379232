import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../../components/template-parts/blog-post"

export default ({ data, location }) => (
  <BlogPost data={data} location={location} />
)

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpCasestudy(id: { eq: $id }) {
      id
      title
      excerpt
      content
      featuredImage {
        node {
          ...casestudyImage
        }
      }
      acf_postImage {
        postImage {
          ...featuredImage
        }
      }
      acf_page_content {
        lead
      }
      seo {
        ...WpYoastSEO
      }
    }

    nextPage: wpCasestudy(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpCasestudy(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export const casestudyImage = graphql`
  fragment casestudyImage on WpMediaItem {
    altText
    sourceUrl
    id
    modified
    description
    localFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
